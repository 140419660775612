import React from 'react';

const IconBookmark = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="1"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather feather-bookmark">
    <title>Bookmark</title>
    <path d="M30.2 29.8V61.8H49.4V55.4H36.6V49H46.2V42.6H36.6V36.2H49.4V29.8H30.2"></path>
  </svg>
);

export default IconBookmark;
